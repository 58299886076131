<template>
  <v-content>
    <v-container>
      <v-layout align-center> Ping turns page </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {}
</script>
